<template>
  <v-container fluid>
    <v-overlay :absolute="absolute" :value="overlay" z-index="90">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-row>
      <v-col>
        <v-btn
          class="ma-2 secondary--text font-weight-bold"
          to="/program_management/programlist"
          color="primary"
          :disabled="!getters_abilities.includes('new_program_access')"
        >
          PROGRAM LIST
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text primary-title>
            <v-form ref="form" lazy-validation v-model="valid">
              <v-row justify="center">
                <v-col cols="12" lg="4">
                  <v-text-field
                    outlined
                    class="rounded-0"
                    v-model="program_name"
                    label="Program Name"
                    :rules="[(v) => !!v || 'Program Name is required']"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" lg="4">
                  <v-switch inset v-model="program_state">
                    <template v-slot:label>
                      <div
                        :class="
                          program_state
                            ? 'primary--text font-weight-bold subheader'
                            : 'grey--text text-decoration-line-through'
                        "
                      >
                        Top-Up
                      </div>
                    </template>
                  </v-switch>
                </v-col>

                <v-col cols="12" lg="4">
                  <v-select
                    outlined
                    :menu-props="{ down: true, offsetY: true }"
                    :items="getters_certificate"
                    item-text="certificate_name"
                    class="rounded-0"
                    single-line
                    item-value="id"
                    label="Certficate"
                    v-model="certificate_id"
                    :rules="[(v) => !!v || 'Certificate is required']"
                    hide-details="auto"
                  >
                    <template slot="selection" slot-scope="data">
                      <span> {{ data.item.certificate_name }}</span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="4">
                  <v-autocomplete
                    @keyup="debouncesearchDepartment"
                    :loading="isLoaded"
                    v-model="department_id"
                    :items="itemsDepartment"
                    :search-input.sync="searchDepartment"
                    class="rounded-0"
                    clearable
                    hide-details="auto"
                    hide-selected
                    item-text="office_name"
                    item-value="id"
                    label="Search Department"
                    outlined
                    :rules="[(v) => !!v || 'Department is required...']"
                  >
                    <template #item="{ item }">
                      <span v-if="item.office_type.toLowerCase() == 'office'">
                        <b class="error--text">
                          {{ `${item.office_type} of  the` }}
                        </b>
                        {{ item.office_name }}
                      </span>
                      <span v-else>
                        <b class="black--text">
                          {{ `${item.office_type} of ` }}
                        </b>
                        {{ item.office_name }}
                      </span>
                    </template>
                    <template #selection="{ item }">
                      <span v-if="item.office_type.toLowerCase() == 'office'">
                        <b class="black--text">
                          {{ `${item.office_type} of  the` }}
                        </b>
                        {{ item.office_name }}
                      </span>
                      <span v-else>
                        <b class="black--text">
                          {{ `${item.office_type} of ` }}
                        </b>
                        {{ item.office_name }}
                      </span>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          <strong>Searching... </strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" lg="4">
                  <v-select
                    hide-details="auto"
                    multiple
                    outlined
                    :items="getters_grades"
                    class="rounded-0"
                    item-text="name"
                    v-model="grade_id"
                    item-value="id"
                    label="Grading Sysetm(s)"
                    :rules="[
                      (v) =>
                        v.length > 0 ||
                        'At least one grading system is required',
                    ]"
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" lg="2">
                  <v-text-field
                    outlined
                    class="rounded-0"
                    type="number"
                    label="Credit Hours"
                    :rules="[(v) => !!v || 'Minimum Credit Hours is required']"
                    v-model.number="min_credit_hours"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" lg="2">
                  <v-text-field
                    class="rounded-0"
                    name="name"
                    label="Duration"
                    v-model.number="duration"
                    min="1"
                    :rules="[(v) => !!v || 'Certificate Duration is required']"
                    outlined
                    type="number"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-row justify="center">
              <v-col cols="2">
                <v-btn
                  block
                  large
                  class="mx-2"
                  color="primary"
                  @click="saveProgramBtn"
                  :disabled="!valid"
                >
                  SAVE
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <Response v-if="successFeedback">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>
<script>
  import { provide, reactive, ref, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import Response from "@/components/ActionResponse/Response";

  export default {
    components: { Response },
    setup() {
      const { getGrades, getOrganogram, saveProgram, getCertificate } =
        useActions([
          "getGrades",
          "getOrganogram",
          "saveProgram",
          "getCertificate",
        ]);

      const {
        getters_certificate,
        getters_organogram,
        getters_grades,
        getters_abilities,
      } = useGetters([
        "getters_certificate",
        "getters_organogram",
        "getters_grades",
        "getters_abilities",
      ]);

      Promise.all([getGrades(), getCertificate()]);

      const programForm = reactive({
        program_state: false,
        dialog: false,
        min_credit_hours: null,
        program_name: "",
        certificate_name: "",
        certificate_id: null,
        duration: null,
        basket: {},
        searchDepartment: null,
        searchGrades: null,
        itemsDepartment: [],
        department_id: null,
        isLoaded: false,
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        deleteResponse: false,
        successFeedback: false,
        absolute: true,
        overlay: false,
        valid: true,
        grade_id: [],
        color: null,
      });

      const form = ref(null);

      const {
        grade_id,
        basket,
        min_credit_hours,
        program_name,
        certificate_id,
        duration,
        searchDepartment,
        itemsDepartment,
        isLoaded,
        department_id,
        program_state,
        msgHeader,
        msgBody,
        msgIcon,
        deleteResponse,
        color,
        successFeedback,
        overlay,
      } = toRefs(programForm);

      const debouncesearchDepartment = debounce(() => {
        if (searchDepartment.value) {
          if (itemsDepartment.value.length > 0) return;
          isLoaded.value = true;

          getOrganogram()
            .then(() => {
              itemsDepartment.value = getters_organogram.value.filter(
                (item) =>
                  item.office_type.toLowerCase() == "department" &&
                  item?.staff_division?.division.toLowerCase() == "academic"
              );
            })
            .finally(() => (isLoaded.value = false));
        }
      });

      const saveProgramBtn = () => {
        deleteResponse.value = false;
        successFeedback.value = false;
        if (form.value.validate()) {
          basket.value = {
            min_credit_hours: min_credit_hours.value,
            organogram_id: department_id.value,
            program_name: program_name.value,
            certificate_id: certificate_id.value,
            duration: duration.value,
            program_state: program_state.value,
            grade_id: grade_id.value,
          };

          overlay.value = true;

          saveProgram(basket.value)
            .then(() => {
              form.value.reset();
              basket.value = {};
              color.value = "success";
              msgHeader.value = "Success";
              msgBody.value = "Saved";
              msgIcon.value = "mdi-check-circle";
            })
            .catch((e) => {
              form.value.reset();
              msgHeader.value = "Error";
              color.value = "error";
              msgIcon.value = "mdi-close-circle";

              switch (e.response.status) {
                case 422:
                  msgBody.value = e.response.data.message;
                  break;
                case 403:
                  msgBody.value = e.response.data.message;
                  break;
                default:
                  msgBody.value =
                    "Try Again Later Or Call The System Administrator";
                  break;
              }
            })
            .finally(() => {
              program_state.value = false;
              overlay.value = false;
              successFeedback.value = true;
            });
        }
      };

      provide("color", color);

      return {
        ...toRefs(programForm),
        getters_grades,
        saveProgramBtn,
        getters_certificate,
        getters_organogram,
        debouncesearchDepartment,
        form,
        getters_abilities,
      };
    },
  };
</script>
<style>
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.03);
  }
</style>
